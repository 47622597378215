@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@300;400;500;600;700;800&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --blue-color: #295199;
  --orange-color: #FE5F13;
  --white-color: #fff;
  --p-color: #808080;
  --dark-color: #221F1FF7;
}
html {
  scroll-behavior: smooth !important;
}

.seo-main p{margin: 0;}
main.marketing-main {
  overflow-x: hidden !important;
}
.calls {
  gap: 10px;
}
.contact-usb {
  background: #fe5f15;
  color: #fff;
  font-size: 18px !important;
  padding: 10px 40px !important;
  font-family: 'Raleway';
  border: 0;
  margin-top: 15px;
}
.marketing-main h1, .seo-main h1 {
  font-family: "Mukta", sans-serif;
}
 .marketing-main header,  .seo-main header {
  height: auto;
  padding-top: 50px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99999;
}

.marketing-main h2, .seo-main h2 {
  font-family: Mukta !important;
  font-size: 48px !important;
  font-weight: 600 !important;
  line-height: 60px !important;
  color: var(--blue-color) !important;
  text-transform: capitalize;

}


.marketing-main .brokerage-text h3,
.marketing-main .digital-marketing-box h3 {
  font-family: Raleway;
  font-size: 30px !important;
  line-height: 36px;
  margin: 0px 0px 13px !important;
  color: var(--orange-color) !important;
}

.marketing-main h3 {
  font-family: Raleway;
  font-size: 25px !important;
  font-weight: 600 !important;
  line-height: 35px;
  color: var(--white-color) !important;
  margin: 18px 0px 10px !important;
}

.seo-main h5{ font-family: Raleway;}
.marketing-main h4 {
  font-family: Mukta;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 30px;
  color: var(--blue-color) !important;
  margin-bottom: 0px !important;
}

.marketing-main p.sub-heading {
  font-family: Raleway;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: var(--orange-color);
}
.seo-main p{  font-family: "Raleway", sans-serif;}
.marketing-main p {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  margin-bottom: 0px !important;
  color: var(--p-color);
  line-height: 26px;
  font-weight: 400;
}

.marketing-main ul ,
.seo-main ul {
  list-style: none;
  padding: 0px !important;
  margin: 0px !important;
}

.marketing-main ul li,
.seo-main ul li {
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: var(--p-color);
}

.marketing-main a, .seo-main a {
  font-size: 16px !important;
  color: var(--white-color) !important;
  background: var(--orange-color);
  padding: 21px 60px;
  text-decoration: none !important;
}

.marketing-main .main-btn-site {
  background-color: var(--blue-color);
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  color: var(--white-color);
  padding: 18px 38px;
  display: inline-block;
}

/**header css**/
.marketing-main .app {
  position: relative;
}

.marketing-main header, .seo-main header {
  padding-top: 50px;
  position: absolute;
  height: auto;
  top: 0;
  width: 100%;
  z-index: 99999;
}
.marketing-main .brand-logo-column, .seo-main .brand-logo-column {
  text-align: left;
  display: flex;
  align-items: center;
}

.marketing-main .brand-logo, .seo-main .brand-logo {
  background: transparent;
  all: unset !important;
}

.marketing-main header a.brand-logo img, .seo-main header a.brand-logo img {
  max-width: 300px;
}

.marketing-main .banner-sec {
  background: url('../marketing-images/banner-bg.webp') no-repeat;
  background-size: cover;
  background-position: bottom;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 106px 0px;
}

.marketing-main .banner-content h1 {
  color: #fff;
  text-align: left;
  font-size: 70px;
  font-weight: 600;
  line-height: 80px;
  text-transform: capitalize;
}

.marketing-main .banner-content b {
  font-weight: 600 !important;
  position: relative;
  padding: 0px 8px !important;
}

.marketing-main .banner-content span {
  position: relative;
  z-index: 3;
  display: inline-block;
}

.marketing-main .banner-content h1 span:before{
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 80px;
  background: #fe5f13;
  top: 50%;
  transform: translateY(-50%);
}
.banner-row span {
  display: inline-block;
  position: relative;
  z-index: 3;
}

/* .banner-row span:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 74px;
  background: #fe5f13;
  top: 50%;
  transform: translateY(-50%);
} */

.banner-row span b {
  position: relative;
  /* padding: 0 8px !important; */
  font-weight: 600;
}
.marketing-main .banner-img img {
  max-width: 100%;

}

.marketing-main .banner-img {
  float: right;
}

.marketing-main section.banner-sec .container {position: relative;}

.marketing-main section.banner-sec .container a.scroll-bottom {
    all: unset !important;
    color: #fff !important;
    font-family: Raleway;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 20px;
    text-orientation: mixed !important;
    display: inline !important;
    writing-mode: vertical-rl !important;
    position: relative;
    cursor: pointer !important;
}

.marketing-main section.banner-sec .container a.scroll-bottom:before {
    content: '';
    position: absolute;
    left: 24px;
    bottom: -85px;
    width: 1px;
    height: 87px;
    background: white;
}

/***common_challenges sec csss***/
.marketing-main .common_challenges {
  padding: 130px 0px;
  text-align: left !important;
  background-color: #FAFAFA;
  background-image: url('../marketing-images/background-shapes-challenges.svg'), url("../marketing-images/marketingbg.webp");
  background-repeat: no-repeat;
  background-size: auto, 100%;
  background-position: left bottom, top left;
}

.marketing-main section.common_challenges h2 {
  margin-bottom: 50px;
  text-align: center !important;
}

.marketing-main .common-faced-challenges {
  background: url('../marketing-images/person-challenges-img.webp') no-repeat;
  background-position: center bottom;
  background-size: 604px;

}

.marketing-main .info-box-challenges {
  box-shadow: 0px 4px 3px 0px #2F2E2E40;
  padding: 47px 0px 30px;
  border-radius: 15px;
  background-color: #fff;
}

.marketing-main .top-bar-info {
  background: var(--blue-color);
  padding: 12px 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 17px;
}

.marketing-main .icon-contain {
  min-width: 75px;
  min-height: 75px;
  background: var(--orange-color);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.marketing-main .info-box-challenges p {
  padding: 30px 30px 0px;
}

.marketing-main .top-bar-info h3 {
  margin: 0px !important;
  text-transform: capitalize;
}

.marketing-main .common-faced-challenges .col-md-4>div:last-child {
  margin-top: 40px;
}

.marketing-main .info-box-challenges.left-side-info {
  position: relative;
  left: 50px;
}

.marketing-main .info-box-challenges.right-side-info {
  position: relative;
  right: 50px;
}

.marketing-main .lastchallenges-row {
  margin-top: 40px !important;
  gap: 80px;
  justify-content: center;
  flex-wrap: nowrap;
}
.marketing-main .col-md-4.offset-md-2.ms-0 {
  margin-right: 16.66666667%;
}

.marketing-main .row.lastchallenges-row .col-md-4 > div {
  height: 100%;
}
.marketing-main .digital-marketing-box h3{
  text-transform: capitalize;
}

.marketing-main .websitehelp{
  padding: 130px 0px;
  background-color: #fff;
}
.marketing-main .websitehelp .data-listes {
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
  position: relative;
}
.marketing-main .websitehelp .data-content {
  position: absolute;
  bottom: 0;
  width: max-content;
  background: #fff;
  padding: 6px 16px;
}
.marketing-main section.websitehelp .row {
  row-gap: 25px;
}

.marketing-main .data-content h3 {
  color:var(--orange-color) !important;
  margin: 0px !important;
  text-transform: capitalize;
}
.marketing-main .img-box-holder {
  padding: 15px;
}
.marketing-main .websitehelp h2{
  padding-bottom: 20px;
  margin-bottom: 0px;
}
.marketing-main .websitehelp img{
  max-width: 100%;
}

.marketing-main .websitehelp h3 {
  color: var(--orange-color) !important;
  margin: 20px 0px 0px !important;
  text-transform: capitalize;
  text-align: center;
  padding: 0px 10px;
  font-size: 20px;
}
.marketing-main section.websitehelp .card{
  border: 2px solid #ccc !important;
}
.marketing-main .websitehelp .inner_col {
  padding: 3px 20px 6px;
  border-bottom: 2px solid #ccc !important;
}

.marketing-main .websitehelp .inner_col img {
  opacity: 0.2;
  width: 14px;
  margin-right: 3px;
  height: 14px;
}



.marketing-main .elevate-brokerage {
  background: #F8F8F8;
  padding: 130px 0px;
  background-image: url('../marketing-images/bg-circle.webp'), url("../marketing-images/website-development-bg.svg"), url("../marketing-images/dots-effct-web.svg");
  background-repeat: no-repeat;
  background-size: 452px, 604px, 579px;
  background-position: left 95%, right bottom, right top;

}

.marketing-main .elevate-img img {
  max-width: 100%;
}

.marketing-main section.elevate-brokerage h2 {
  margin-bottom: 50px;
  text-align: center !important;
}

.marketing-main .elevate-img img {
  max-width: 650px;
  width: 100%;
}

.marketing-main .brokerage-text {
  padding-left: 44px;
}


.marketing-main .brokerage-text p {
  margin-bottom: 13px !important;
}

.marketing-main .brokerage-text a {
  margin-top: 22px;
  display: block;
  width: max-content;
}

.marketing-main .brokerage-text p.m-0 {
  margin: 0px !important;
}

/***digital marketing css***/

.marketing-main .digital-marketing {
  background-color: #F8F8F8;
  padding-bottom: 130px;
  text-align: center;
}

.marketing-main .digital-marketing-box {
  background: #fff;
  padding: 100px 84px;
  box-shadow: 0px 0px 15px 0px #00000026;
  background-image: url("../marketing-images/dotes.webp");
  background-repeat: no-repeat;
  background-size: 110%;
}

.marketing-main .digital-marketing-box img {
  margin: 0px 0px 55px;
  max-width: 100% ;
}

.marketing-main .digital-marketing-box p {
  margin-bottom: 14px !important;
}

.marketing-main .digital-marketing-box a {
  display: block;
  width: max-content;
  margin: 0 auto;
}

/***about us css**/

.marketing-main .aboutus {
  background: url("../marketing-images/about-us-bg.webp") no-repeat;
  background-size: cover;
}

.marketing-main .about-us-data {
  background: var(--blue-color);
  padding: 100px 40px;
}

.marketing-main .about-us-data h2 {
  color: var(--orange-color) !important;
}

.marketing-main .about-us-data p {
  color: var(--white-color) !important;
  margin-bottom: 10px !important;
}

.marketing-main .about-us-data p.last-p {
  margin-bottom: 0px !important;
}
.inner-guide .image-side img {
  width: 30px;
  height: 30px;
}
/***faq data css start here***/
.faq-data {
  padding: 130px 0px;
  background-image: url("../marketing-images/faq-bg.svg");
  background-repeat: no-repeat;
  background-size: 808px;
  background-position: left top;
}
.seo-main .faq-data {
  padding: 130px 0px;
  background-image: none;
  background-repeat: no-repeat;
  background-size: 808px;
  background-position: left top;
}

section.faq-data .accordion-button::after {
  background-image: url('../marketing-images/plus.svg') !important;
  background: var(--blue-color);
  width: 70px !important;
  height: 70px !important;
  background-position: center;
  background-repeat: no-repeat !important;
  transform: unset !important;

}

section.faq-data .accordion-button:not(.collapsed)::after {
  background-image: url('../marketing-images/minus.svg') !important;
  background: var(--blue-color);
  width: 70px !important;
  height: 70px !important;
  background-position: center;
  background-repeat: no-repeat !important;
  transform: unset !important;
}

section.faq-data h2, .seo-main section.faq-data h2 {
  margin-bottom: 50px;
}
.faq-data span.content-btn-according {
  max-width: 75% !important;
}
.faq-data .accordion-button:not(.collapsed) {
  color: #295199 !important;
  background-color: transparent;
  box-shadow: none !important;
  border-radius: 0px !important;
}

.faq-data .accordion-item:first-of-type,
.faq-data .accordion-item:last-of-type {
  border-radius: 0px !important;
}

.faq-data .accordion-button {
  padding: 0px 0px 0px 30px;
  font-family: Mukta;
  font-size: 20px;
  font-weight: 500;
  line-height: 33.24px;
  color: var(--p-color) !important;
}

.faq-data .accordion-item {
  margin-bottom: 15px;
}

.faq-data .accordion-button:focus {
  box-shadow: none !important;
}

.faq-data .accordion-item:not(:first-of-type) {
  border-top: 1px solid #dee2e6;
}

.faq-data .accordion-body {
  padding: 20px 30px 20px 70px;
}

.faq-data .accordion-header span.number-text {
  color: #295199 !important;
  display: inline-block;
  min-width: 40px;
  font-weight: 600 !important;
}
.guide-text h4 {
  font-size: 26px;
  position: relative;
  width: max-content;
  margin-bottom: 22px;
}

.guide-text h4:before {
  content: '';
  background: #fe5f15;
  width: 15%;
  height: 2px;
  position: absolute;
  bottom: -8px;
}

.inner-guide:hover .guide-text h4:before {
  width: 100%;
  transition: 0.5s ease;
}



/***contact-us css**/
.marketing-main .contact-us, .seo-main .contact-us  {
  background: url('../marketing-images/bg-contact-with-us.webp') no-repeat;
  background-size: cover;
  padding: 130px 0px 0px;
  overflow: hidden;
  position: relative;
}
.marketing-main .contact-us:before, .seo-main .contact-us:before{
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.7;
  top: 0;
}
.marketing-main .contact-us .container, .seo-main .contact-us .container{
  position: relative;
}
.marketing-main .contact-form-sec, .seo-main .contact-form-sec {
  padding-bottom: 80px;
}

.marketing-main .contact-us h2,
.seo-main .contact-us h2,
.seo-main .contact-us p, 
.marketing-main .contact-us p {
  color: var(--white-color) !important;
  text-align: center;
}

.marketing-main .contact-us .highlight-texts, .seo-main .contact-us .highlight-texts {
  padding: 0;
  margin: 0;
  display: flex;
  gap: 30px;
  justify-content: center;
  margin: 50px 0px !important;
}

.marketing-main .contact-us .highlight-texts li, .seo-main .contact-us .highlight-texts li {
  font-family: Mukta;
  font-size: 24px;
  font-weight: 500;
  color: var(--white-color);

}

.marketing-main .contact-from, .seo-main .contact-from {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background: rgba(41, 81, 153, 0.85);
  padding: 35px 50px;
  position: relative;
}
.marketing-main .contact-from h3, .seo-main .contact-from h3{
   color: #fff;
    font-size: 25px;
    font-weight: 600;
    font-family: Raleway;
    line-height: 35px;
    margin: 0px 0 15px;
  text-transform: capitalize;
}

.marketing-main .contact-form:before, .seo-main .contact-form:before {
  position: absolute;
  content: '';
  bottom: -135px;
  left: 33%;
  transform: translateX(-50%);
  background: url('../marketing-images/submit-shapes.svg') no-repeat;
  background-size: contain;
  width: 250px;
  height: 153px;
  z-index: 5;
}

.marketing-main form.contact-form .row, .seo-main form.contact-form .row  {
  row-gap: 18px;
}

.marketing-main .contact-from h3, .seo-main .contact-from h3 {
  margin: 0px 0px 15px !important;
}

.marketing-main .contact-from p, .seo-main .contact-from p {
  margin-bottom: 24px !important;
  text-align: left !important;
}

.marketing-main .contact-from input,
.seo-main .contact-from input,
.marketing-main .contact-from textarea,
.seo-main .contact-from textarea,
.marketing-main .contact-from select,
.seo-main .contact-from select,
.marketing-main .contact-from select option,
.seo-main .contact-from select option {
  background: rgba(255, 255, 255, 1) !important;
  border: none !important;
  width: 100%;
  padding: 0px 20px;
  font-family: Raleway;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 21.13px;
  color: rgba(25, 26, 25, 0.6) !important;
  height: 76px;
}

.marketing-main .contact-from input::placeholder,
.seo-main .contact-from input::placeholder,
.marketing-main .contact-from textarea::placeholder,
.seo-main .contact-from textarea::placeholder {
  font-family: Raleway;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 21.13px;
  color: rgba(25, 26, 25, 0.6) !important;


}

.marketing-main .contact-from input:focus,
.seo-main .contact-from input:focus,
.marketing-main .contact-from textarea:focus,
.seo-main .contact-from textarea:focus,
.seo-main .contact-from select:focus,
.marketing-main .contact-from select:focus {
  outline: none !important;
  box-shadow: none !important;
}

.marketing-main .contact-form select,
.seo-main .contact-form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('../marketing-images/downselect-icon.svg') !important;
  background-repeat: no-repeat !important;
  background-position: 93% center !important;
  padding-right: 20px !important;
  background-size: 17px !important;
  text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.marketing-main .contact-form select::-ms-expand,
.seo-main .contact-form select::-ms-expand {
  display: none !important;
}


.contact-from textarea {
  height: 148px !important;
  padding-top: 25px !important;
}

.marketing-main .contact-from input[type="Submit"],
.seo-main .contact-from input[type="Submit"] {
  background: rgba(254, 95, 19, 1) !important;
  margin-top: 20px;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.78px;
  color: #fff !important;
  padding: 23px 10px !important;
  height: 100%;
}

.marketing-main .contact-infomation,
.seo-main .contact-infomation  {
  background: rgba(0, 0, 0, 0.85);
  padding: 39px 35px;
  height: 100%;
}

.marketing-main .contact-infomation h3,
.seo-main .contact-infomation h3 {
  margin: 0px 0px 20px !important;
}

.seo-main .contact-infomation h3 {
  color: #fff;
  margin: 0px 0px 20px !important;
}
.marketing-main .contact-infomation h4,
.seo-main .contact-infomation h4 {
  color: #FE5F13 !important;
  margin-bottom: 13px !important;
}

.marketing-main .contact-infomation a,
.seo-main .contact-infomation a {
  all: unset;
  cursor: pointer !important;
}

.marketing-main p.address,
.seo-main p.address {
  margin-bottom: 30px !important;
  text-align: left !important;
}

.marketing-main .contact-infomation h4.follow-us,
.seo-main .contact-infomation h4.follow-us {
  margin-top: 25px;
  margin-bottom: 16px !important;
}

.marketing-main .contact-infomation ul.follow-us,
.seo-main .contact-infomation ul.follow-us {
  display: flex;
  align-items: center;
  gap: 8px;
}

.marketing-main p.last-p-contact,
.seo-main p.last-p-contact {
  text-align: left;
  margin-top: 25px;
}

.marketing-main ul.conatct-details-links li,
.seo-main ul.conatct-details-links li {
  color: #fff;

}

.marketing-main ul.follow-us li,
.seo-main ul.follow-us li {
  background: rgba(255, 255, 255, 0.2);
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 15px 15px 15px 0px #00000014;
}

.marketing-main ul.follow-us li a,
.seo-main ul.follow-us li a {
  line-height: 0px;
}

.marketing-main .footer-copyright p,
.seo-main .footer-copyright p {
  text-align: right !important;
  padding-bottom: 20px;
}
a.contactus-btn {
  background: #fe5f15;
  color: #fff;
  text-decoration: none;
  font-size: 22px;
  padding: 15px 41px;
}

.banner {
  background-image: url(../marketing-images/bg.webp);
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: top;
  padding-top: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-header {
  position: absolute;
  z-index: 9;
  width: 100%;
  padding: 30px 0px 0px;
}

.banner h1 {
  max-width: 700px;
  color: #fff;
  font-size: 70px;
  font-weight: 600;
  line-height: 80px;
  text-align: left;
  text-transform: capitalize;
}
.headeing-text {
  color: #295199;
  font-size: 48px;
  font-weight: 600;
  font-family: Mukta !important;
  line-height: 60px;
  text-transform: capitalize;
  text-align: center;
}

.seo-main .row.row-mt-10 {
  margin-top: 65px;
  row-gap: 30px;
}

.card-commen {
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 25px;
  background: #fff;
  min-height: 300px;
}

section.common-challange {
  padding: 120px 0;
  /* background-image: url(../marketing-images/wave.webp); */
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #fff;
}

.card-commen h5 {
  font-size: 20px;
  background: #295199;
  padding: 20px;
  color: #fff;
  margin: 0;
  border-radius: 10px 10px 0 0;
}

.card-commen p {
  font-size: 18px;
  padding: 20px;
  margin: 0;
}

.seo-digital {
  padding: 120px 0px;
  background-color: #fff;
}

p.seo-pera {
  max-width: 900px;
  margin: 30px auto;
  text-align: center;
}

.inner-guide::before {
  height: 30px;
  background: #fff;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border-top: 50px solid #ffffff;
  border-left: 50px solid #fe5f15;
  width: 0;
  border-radius: 0 0 0 10px;
}
.cont-link{
    border-bottom: 1px solid;
    background: none !important;
    padding: 0px !important;
}
.list-drop {
  height: 100%;
}
.prparties{
  row-gap: 30px;
}
.list-drop-container {
  padding: 20px 20px 20px 20px;
  background-color: #fff;
  border-radius: 14px 14px 14px 14px;
  box-shadow: 0px 0px 3px 3px rgb(55 100 137 / 8%);
  border: 2px solid transparent !important;
  transition: 0.5s ease;
  position: relative;
  height: 100%;
}

h3.list-drop-title {
  font-family: 'Mukta';
  font-size: 25px;
  line-height: 30px;
}
ul.list-drop-ul {
  color: #fe5f13 !important;
  list-style: disc;
  padding: 16px !important;
}
.list-drop-box-content {
  position: relative;
  z-index: 4;
}

.list-drop:hover .list-drop-container {
  border: 2px solid #fe5f13 !important; 
  transition: 0.5s ease;
}
.inner-guide {
  background: #2951992e;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: self-start;
  height: 100%;
  transition: 0.4s;
}

.inner-guide:hover {
  background: #295199;
}

.inner-guide:hover .guide-text h4 {
  color: #fff;
}

.inner-guide:hover .guide-text p {
  color: #fff;
}

.inner-guide:hover .image-side img {
  filter: invert(1);
}

.inner-guide:hover::before {
  border-left: 50px solid #fe5f15;
}

.common-challange p.seo-pera {
  max-width: 700px;
}

.faq-outer {
  max-width: 850px;
  margin: auto;
  margin-top: 30px;
}

.faq-outer .accordion-item {
  margin-bottom: 10px;
  border: 1px solid #ccc;
}

.faq-outer .accordion-button:not(.collapsed) {
  background: #fff;
}

.faq-outer .accordion-button {
  background: #fff;
}

.faq-outer .accordion-button {
  background: #fff;
  font-size: 20px;
  color: #000;
}

.faq-outer .accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: none;
}

.faq-outer .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.faq-outer .accordion-body {
  border-top: 1px solid #ccc;
}

.faq-outer .accordion-body p {
  font-size: 20px;
  margin-bottom: 10px;
}

.faq-outer .accordion-body ul li {
  font-size: 18px;
  margin: 0;
}

.faq-outer .accordion-body ul {
  font-size: 18px;
  margin: 0;
}

.text-cousmer h2 {
  margin: 0;
  font-size: 38px;
  color: #295199;
}

section.cunsumers-banner {
  padding: 120px 0;
  background-image: url(../marketing-images/download.svg);
  background-size: cover;
  background-color: #fafafa;
}

.text-cousmer h2 span {
  font-weight: 900;
}

.dont-web-banner {
  background-image: url(../marketing-images/dont-have.webp);
  background-size: cover;
  padding: 125px 0px;
  background-position: center;
  background-color: #0000007d;
  background-blend-mode: darken;
}
.web-banner1 {
  background-image: url(../marketing-images/website-idea.webp);
  background-size: cover;
  padding: 125px 0px;
  background-position: center;
  background-color: #0000007d;
  background-blend-mode: darken;
}

.bann-inner h3 {
  font-size: 35px;
  font-family: 'Mukta';
  color: #fff;
  text-transform: none;
}

.col-lg-12.bann-inner {
  max-width: 700px;
  margin: auto;
  text-align: center;
}

.col-lg-12.bann-inner button {
  background: #fe5f15;
  color: #fff;
  font-size: 16px;
  padding: 10px 40px;
  font-family: 'Raleway';
  border: 0;
  margin-top: 15px;
}

section.common-challange.faq {
  background-color: #fafafa;
}

.bann-inner p {
  color: #fff;
  margin-top: 10px;
  font-weight: 400;
}















p.address {
margin-bottom: 30px !important;
text-align: left !important;
}




p.last-p-contact {
text-align: left;
margin-top: 25px;
}

ul.conatct-details-links li {
color: #fff;

}

ul.follow-us li {
background: rgba(255, 255, 255, 0.2);
width: 36px;
height: 36px;
display: flex;
justify-content: center;
align-items: center;
box-shadow: 15px 15px 15px 0px #00000014;
}

ul.follow-us li a {
line-height: 0px;
}


ul.follow-us {
  padding: 0;
}
/* .main-faqs{
  padding: 120px 0;
  background-color: #fff;
} */
/* 
.faq-data-column .accordion-item:first-of-type, .faq-data-column .accordion-item:last-of-type {
  border-radius: 0!important;
}
.faq-data-column .accordion-item {
  margin-bottom: 15px;
}
.faq-data-column .accordion-button {
  color: grey!important;
  color: var(--p-color)!important;
  font-size: 20px;
  font-weight: 500;
  line-height: 33.24px;
  padding: 0 0 0 30px;
}
.faq-data-column h4 {
  color: #295199!important;
  color: #a3a6ab !important;
  font-size: 20px!important;
  font-weight: 600!important;
  line-height: 30px;
  margin-bottom: 0!important;
}
.faq-data-column .accordion-header span.number-text {
  color: #295199!important;
  display: inline-block;
  font-weight: 600!important;
  min-width: 40px;
}
.faq-data-column .accordion-item:not(:first-of-type) {
  border-top: 1px solid #dee2e6;
}
.faq-data-column .accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: none;
}
.faq-data-column .accordion-item:first-of-type>.accordion-header .accordion-button{
  border-radius: 0;
}
.faq-data-column .accordion-button:not(.collapsed) {
  background-color: initial;
  border-radius: 0;
  box-shadow: none;
  color: #295199;
}
.faq-data-column  .accordion-button:after {
  background-image: url(../marketing-images/plus.svg) !important;
  background: #295199; 
  background-position: 50%;
  background-repeat: no-repeat;
  height: 70px;
  transform: none;
  width: 70px;
}
.faq-data-column .accordion-button:not(.collapsed):after {
  background-image: url(../marketing-images/minus.svg) !important;
  background: #295199;
  background-position: 50%;
  background-repeat: no-repeat;
  height: 70px;
  transform: none;
  width: 70px;
} */

.groww {
  background-image: url(../marketing-images/business-growth-success-1.webp);
  background-size: cover;
  padding: 100px 0;
  background-position: center;
  text-align: center;
}
.for-grow {
  color: #fff;
  max-width: 660px;
  position: relative;
  margin: 20px auto;
  text-shadow: 0 0 4px  black;
}


@media (max-width: 1440px){

  .card-commen {
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-bottom: 25px;
    background: #fff;
    min-height: 260px;
}
.card-commen h5 {
  font-size: 16px;
}
.card-commen p {
  font-size: 14px;
}
  .banner h1 {
    max-width: 500px;
      font-size: 45px;
      line-height: 52px;
  }
  .headeing-text {
      font-size: 38px;
      line-height: 48px;
  }
  p{
      font-size: 15px;
  }
  .bann-inner h3 {
      font-size: 30px;
  }
  .guide-text h4 {
      font-size: 18px;
  }
  .faq-outer .accordion-button {
      font-size: 18px;
  }
  .faq-outer .accordion-body p {
      font-size: 18px;
  }
  .faq-outer .accordion-body ul li {
      font-size: 16px;
  }
  .text-cousmer h2 {
      font-size: 32px;
  }
  section.main-faqs h3 {
      font-size: 38px;
  }
  .faq-data-column .accordion-button {
      font-size: 18px;
  }
  .contact-us h2 {
      font-size: 38px;
      line-height: 48px;
  }
  .contact-us .highlight-texts li {
      font-size: 20px;
  }
  .contact-from h3 {
      
      font-size: 22px;
  }
  .contact-infomation h4 {
      font-size: 22px;
  }
  .contact-infomation h4.follow-us{
      font-size: 22px;
  }
}

@media (max-width: 767px){
  /* .banner-row span:before {
    height: 32px;
} */
  .ban-ing{
    margin: 60px auto;
    max-height: 900px;
  }
  .banner-row .col-md-7 {
    order: 1 !important;
}
  .contact-form:before{
      display: none;
  }
  .groww {
      padding:60px 0;
      background-position: right;
  }
  .card-commen h5 {
      font-size: 18px;
  }
  .banner h1 {
    max-width: 400px;
    font-size: 27px;
    margin: 0 auto;
    line-height: 33px;
  }
  .banner {
      height: auto;
      padding-bottom: 40px;
      padding-top: 100px;
  }
  .col-5.brand-logo-column img {
      width: 160px;
  }
  a.contactus-btn {
      font-size: 18px;
      padding: 8px 21px;
  }
  section.common-challange {
      padding: 60px 0;
  }
  .headeing-text {
      font-size: 24px;
      line-height: 30px;
  }
 
  .card-commen {
      margin-bottom: 15px;
      min-height: auto;
  }
  .dont-web-banner {
      padding: 40px 0px;
  }
  .bann-inner h3 {
      font-size: 28px;
      line-height: 30px;
  }
  .col-lg-12.bann-inner button {
      padding: 6px 32px;
  }
  .seo-digital {
      padding: 60px 0px;
  }
  p.seo-pera {
      font-size: 16px;
      line-height: 22px;
  }
  .inner-guide {
      flex-direction: column;

  }
  .image-side {
      width: 100%;
  }
  .faq-outer .accordion-button {
    font-size: 18px;
    line-height: 28px;
  }
  section.cunsumers-banner {
      padding: 60px 0;
  }
  .text-cousmer h2 {
    line-height: 40px !important;
    font-size: 28px !important;
    text-align: center;
}
  .col-lg-6.text-cousmer {
      margin-bottom: 20px;
  }
  .main-faqs {
      padding: 60px 0;
      background-color: #fff;
  }
  section.main-faqs h3 {
      font-size: 27px;
      line-height: 29px;
      text-align: center;
  }
  .faq-data-column {
      margin-top: 30px;
  }
  .container {
      padding: 0 20px;
  }
  .faq-data-column .accordion-button {
      padding: 0 0 0 10px;
  }
  .faq-data-column .accordion-button {
      font-size: 14px;
      line-height: 21.24px;
  }
  .faq-data-column  .accordion-button:after {
      height: 50px;
      width: 50px;
  }
  .faq-data-column .accordion-button:not(.collapsed):after {
      height: 50px;
      width: 50px;
  }
  .contact-us {
      padding: 60px 0 0;
  }
  .contact-us h2 {
      font-size: 35px;
      line-height: 45px;
  }
  .contact-us .highlight-texts li {
      font-size: 14px;
  }
  .contact-us .highlight-texts {
      flex-wrap: wrap;
      row-gap: 7px;
      margin: 22px 0 !important;
  }
  .contact-from {
      padding: 15px 15px;
  }
  .contact-from input, .contact-from select, .contact-from textarea {
      height: 44px;
  }
  .contact-from input[type="Submit"] {
      margin-top: 0px;
      font-size: 16px;
      line-height: 18.78px;
      padding: 14px 10px !important;
  }
  .contact-infomation {
      padding: 15px 15px;
  }
  .footer-copyright p {
      text-align: center;
      font-size: 16px;
  }
.contact-infomation {
      margin-top: 30px;
  }
}