@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@300;400;500;600;700;800&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --blue-color: #295199;
  --orange-color: #FE5F13;
  --white-color: #fff;
  --p-color: #808080;
  --dark-color: #221F1FF7;
}

main.home-main {
  overflow-x: hidden;
}

body {
  overflow-x: hidden !important;
}

.home-main div#preloader-status img {
  width: 261px;
}
.privacy-blk {
  padding: 130px 0px;
}
.home-main div#preloader {
  position: fixed;
  text-align: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  z-index: 9999;
}

.home-main h1,
.home-main h2 {
  font-family: "Mukta", sans-serif;
}

.home-main .aboutus-sec h2,
.home-main .ourservices h2,
.home-main h2 {
  font-family: Mukta !important;
  font-size: 48px !important;
  font-weight: 600 !important;
  line-height: 60px !important;
  color: var(--blue-color) !important;
  margin-bottom: 30px;

}

.home-main h3 {
  font-family: Mukta;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 30px;
  color: var(--blue-color) !important;
  margin: 18px 0px 10px !important;
}

.home-main h4 {
  font-family: Mukta;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 30px;
  color: var(--blue-color) !important;
  margin-bottom: 5px !important;
}

.home-main p.sub-heading {
  font-family: Raleway;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: var(--orange-color);
}

.home-main p {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  margin-bottom: 0px !important;
  color: var(--p-color);
  line-height: 26px;
  font-weight: 400;
}

.home-main ul {
  list-style: none;
  padding: 0px !important;
  margin: 0px !important;
}

.home-main ul li {
  font-family: Raleway;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  color: var(--p-color);
}

.home-main ul li:last-child {
  margin-bottom: 0px;
}

.home-main .main-slider-one__link a {
  background-color: transparent;
  border: 1px solid var(--orange-color);
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  color: var(--orange-color) !important;
  text-decoration: none;
  padding: 15px 67px;
}

.home-main a.contact-us-btn {
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  color: #fff;
  background: #295199;
  padding: 15px 55px;
  text-decoration: none;
}

/***header css***/


.home-main header {
  background: #FFFFFFB2;
  position: fixed;
  top: 0;
  width: 100%;
  height: auto;
  z-index: 999;
  border-bottom: 1px solid var(--orange-color);
}

.home-main header.is-sticky {
  background: #fff !important;
  -webkit-transition: all 1.3s;
  -moz-transition: all 1.3s;
  -ms-transition: all 1.3s;
  -o-transition: all 1.3s;
  transition: all 1.3s;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.75s;
}
.home-main .banner-b{
  text-transform: uppercase !important;
  font-weight: 600 !important;
}

.home-main #navbarSupportedContent{
  justify-content: flex-end !important;
}

/* @keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
} */


.home-main header nav.navbar.navbar-expand-lg {
  padding: 30px 0px !important;
}

.home-main header a.navbar-brand {
  padding: 0px !important;
}

.home-main header a.navbar-brand img {
  width: 190px;
  object-fit: contain;
}

.home-main header .navbar-expand-lg .navbar-nav .nav-link {
  font-family: Mukta;
  font-size: 16px;
  font-weight: 400;
  line-height: 26.59px;
  color: var(--blue-color);
  padding: 0px 0px !important;
}

.home-main header .navbar-expand-lg .navbar-nav li.nav-item {
  display: flex;
  align-items: center;
  padding: 0px 25px !important;
}

.home-main header .navbar-expand-lg .navbar-nav li.nav-item:last-child {
  padding-right: 0px !important;
}

.home-main header .navbar-expand-lg .navbar-nav li:last-child a {
  border: 1px solid var(--orange-color);
  color: var(--orange-color);
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 170px;
}



body.offcanvas-active {
  overflow: hidden;
}

.home-main .offcanvas-header {
  display: none !important;
}

.screen-darken {
  height: 100%;
  width: 0%;
  z-index: 30;
  position: fixed;
  top: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(34, 34, 34, 0.6);
  transition: opacity .2s linear, visibility 0.2s, width 2s ease-in;
}

.bg-show .screen-darken {
  z-index: 999;
  transition: opacity .3s ease, width 0s;
  opacity: 1;
  width: 100%;
  visibility: visible;
}


/***banner sec css start here***/

/* .preview-banner_main {
  padding-top: 180px;
  padding-bottom: 221px;
  position: relative;
  height: 751px;
} */

/* .preview-banner{
  background-image: url('../Images/campaign-creators-img.webp') !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 105px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  z-index: 99;

} */

.home-main .main-slider-one__bg {
  background-image: url('../Images/campaign-creators-img.webp') !important;
}


.home-main .main-slider-two__bg {
  background-image: url('../Images/banner-img.webp') !important;
}

.home-main .main-slider-three__bg {
  background-image: url('../Images/banner-img-03.webp') !important;
}

/*--------------------------------------------------------------
# Hero Slider
--------------------------------------------------------------*/
.home-main .main-slider-one {
  position: relative;
  background: var(--ostech-white, #fff);
}

.home-main .main-slider-one__carousel {
  position: relative;
  width: 100%;
}

.home-main .main-slider-one__carousel .owl-dots {
  top: 75%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 767px) {
  .home-main .main-slider-one__carousel .owl-dots {
    top: auto;
    bottom: 70px;
  }
}

.home-main .main-slider-one__carousel .owl-dots .owl-dot {
  display: block;
  margin: 6px 0;
}

@media (max-width: 767px) {
  .home-main .main-slider-one__carousel .owl-dots .owl-dot {
    margin: 0 6px;
  }
}

.home-main .main-slider-one__carousel .owl-dots .owl-dot span {
  display: block;
  margin: 0;
  transition: all 300ms ease;
  width: 29.945px;
  height: 29.945px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-main .main-slider-one__carousel .owl-dots .owl-dot span::before {
  content: "";
  width: 10px;
  height: 10px;
  background: var(--ostech-gray, #F4F5F8);
}

.home-main .main-slider-one__carousel .owl-dots .owl-dot:hover span,
.home-main .main-slider-one__carousel .owl-dots .owl-dot.active span {
  background: rgba(104, 106, 111, 0.23);
}

.home-main .main-slider-one__carousel .owl-dots .owl-dot:hover span::before,
.home-main .main-slider-one__carousel .owl-dots .owl-dot.active span::before {
  background: var(--ostech-base, #FFAA17);
}

.home-main .main-slider-one__item {
  position: relative;
  z-index: 3;
  padding-top: 300px;
  padding-bottom: 300px;
}

@media (max-width: 1300px) {
  .home-main .main-slider-one__item {
    padding-top: 200px;
    padding-bottom: 250px;
  }
}

@media (max-width: 767px) {
  .home-main .main-slider-one__item {
    padding-top: 200px;
    padding-bottom: 150px;
  }
}

@media (max-width: 575px) {
  .home-main .main-slider-one__item {
    padding-top: 150px;
  }
}

.home-main .main-slider-one__item .container {
  max-width: 1250px;
  margin-left: auto;
  margin-right: auto;
}

.home-main .main-slider-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 105px;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}


@media (max-width: 767px) {
  .home-main .main-slider-one__bg {
    bottom: 0;
  }
}

.home-main .main-slider-one__shape.main-slider-one__shape--one img {
  max-width: 100%;
  height: 700px !important;
  object-fit: contain;
}

.home-main .main-slider-one__content {
  position: relative;
  z-index: 3;
  overflow: hidden;
  text-align: center;
  max-width: 950px;
  width: 100%;
  background: #FFFFFFC2;
  height: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 2000ms cubic-bezier(0.65, 0, 0.076, 1);
  transition-delay: 2s;
  z-index: 1;
}

.home-main .main-slider-one .active .main-slider-one__content {
  transition: opacity 2000ms ease, opacity 1000ms ease;
  opacity: 1;
  transition-delay: 1100ms;
}


.home-main .main-slider-one__sub-title {
  color: var(--ostech-base, #FFAA17);
  margin: 0 0 -7px;
  transition: transform 1000ms ease, opacity 1000ms ease;
  transform: translateY(-500px);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

@media (max-width: 991px) {
  .home-main .main-slider-one__sub-title {
    margin: 0 0 10px;
  }
}

.home-main .main-slider-one__sub-title span {
  background: var(--blue-color);
  padding: 12px 30px;
  display: inline-block;
  font-family: Raleway;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  color: var(--white-color);
  text-transform: capitalize;
}

.home-main .main-slider-one__title {
  font-family: Mukta;
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  text-align: center;
  display: inline-block;
  margin: 0 0px 30px;
  text-transform: capitalize;
  opacity: 0;
  transition: transform 1200ms ease, opacity 1200ms ease;
  transform: translateX(200px);
  color: var(--blue-color);
  margin: 28px 0px 43px;
}

.home-main .main-slider-one__title span {
  color: #FE5F13;
}

.home-main .main-slider-one__title__overlay {
  background-color: var(--ostech-white, #fff);
  width: 16.66%;
  transform: scaleX(1);
  height: calc(100% - 2px);
  display: block;
  position: absolute;
  transition: 1000ms cubic-bezier(0.65, 0, 0.076, 1);
  transition-delay: 2s;
  z-index: 1;
}

.home-main .main-slider-one__title__overlay:nth-child(2),
.home-main .main-slider-one__title__overlay:nth-child(4),
.home-main .main-slider-one__title__overlay:nth-child(6) {
  top: 1px;
}

.home-main .main-slider-one__title__overlay:nth-child(1),
.home-main .main-slider-one__title__overlay:nth-child(3),
.home-main .main-slider-one__title__overlay:nth-child(5) {
  bottom: 1px;
}

.home-main .main-slider-one__title__overlay:nth-child(2) {
  left: 16.66%;
}

.home-main .main-slider-one__title__overlay:nth-child(4) {
  left: 49.98%;
}

.home-main .main-slider-one__title__overlay:nth-child(6) {
  left: 83.3%;
}

.home-main .main-slider-one__title__overlay:nth-child(1) {
  left: 0;
}

.home-main .main-slider-one__title__overlay:nth-child(3) {
  left: 33.34%;
}

.home-main .main-slider-one__title__overlay:nth-child(5) {
  left: 66.64%;
}


.home-main .main-slider-one__link {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  opacity: 0;
  overflow: hidden;
  z-index: 5;
  transform: perspective(400px) rotateY(0deg) translateY(80px);
  transform-origin: bottom;
  transition: all 1500ms ease;
}

.home-main .main-slider-one__link__btn::before,
.home-main .main-slider-one__link__btn::after {
  background: var(--ostech-gray, #F4F5F8);
}

.home-main .main-slider-one__link__btn:hover {
  color: var(--ostech-secondary, #232429);
}

.home-main .main-slider-one__shape--one {
  position: absolute;
  bottom: 63px;
  right: 0px;
  transform: translateX(10%);
  opacity: 0;
  transition: transform 1000ms ease, opacity 1000ms ease;
}

@media (max-width: 767px) {
  .home-main .main-slider-one__shape--one {
    display: none;
  }
}

.home-main .main-slider-one__shape--two {
  position: absolute;
  bottom: 1px;
  left: 0px;
}

@media (max-width: 767px) {
  .home-main .main-slider-one__shape--two {
    display: none;
  }
}

.home-main .main-slider-one .active .main-slider-one__sub-title {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 1100ms;
}

.home-main .main-slider-one .active .main-slider-one__title {
  opacity: 1;
  transform: translateX(0);
  transition-delay: 1300ms;
}

.home-main .main-slider-one .active .main-slider-one__title__overlay {
  transform: scaleX(0);
}

.home-main .main-slider-one .active .main-slider-one__link {
  opacity: 1;
  transform: perspective(400px) rotateY(0deg) translateY(0px);
  transition-delay: 1700ms;
}

.home-main .main-slider-one .active .main-slider-one__shape--one {
  transform: translateX(0%);
  opacity: 1;
  transition-delay: 1700ms;
}


/**** about us section css start here****/
.home-main .aboutus-sec {
  padding: 86px 0px 106px;
  position: relative;
  scroll-margin-top: 30px;

}

.home-main .aboutus-sec:before {
  content: '';
  position: absolute;
  top: -39px;
  left: 0px;
  width: 100%;
  max-width: 455px;
  height: 100%;
  background: url("../Images/about-bg-shape.svg") no-repeat;
  z-index: 1;
}

.home-main .aboutus-sec .container-fluid {
  position: relative;
  z-index: 3;
}

.home-main .about-company {
  max-width: 806px;
  margin-left: auto;
  padding-right: 30px;
}

.home-main .why-select-us {
  margin-top: 50px;
}

.home-main .why-select-us-box {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.home-main .icon-img-select {
  max-width: 98px !important;
  width: 100%;
}

.home-main .our-features-details {
  margin-top: 50px;
}

.home-main .greate-platform {
  box-shadow: 0px 0px 15px 0px #00000026;
  background: #fff;
  padding: 25px 15px 25px 15px;
  display: flex;
  align-items: center;
  gap: 20px;
  border-left: 1px solid var(--blue-color);
  position: relative;
}

.home-main .greate-platform:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
  background: var(--orange-color);
}

.home-main .greate-platform:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0px;
  width: 110px;
  height: 1px;
  background: var(--blue-color);
}

.home-main .user-img {
  width: 40%;
  overflow: hidden;
  min-width: 106px !important;
}

.home-main .user-img img {
  width: 100% !important;
  max-width: 100%;
  height: 100% !important;
  border-radius: 50%;
}

.home-main .user-content {
  width: 60%;
}

.home-main .user-description {
  padding-left: 10px;
}

.home-main .our-features-details ul.features-list {
  margin-top: 14px !important;
}

.home-main ul.features-list li img {
  width: 18px;
  margin-right: 6px;
}

.home-main .about-company a.contact-us-btn {
  margin-top: 60px;
  display: inline-block;
}

.home-main .it-soluctions-img {
  position: relative;
  height: 100%;
  max-height: 656px;
}

.home-main .it-soluctions-img img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}

.home-main .it-soluctions-img:after {
  content: '';
  position: absolute;
  bottom: -85px;
  right: 0px;
  width: 100%;
  max-width: 668px;
  height: 170px;
  z-index: -1;
  background: url("../Images/about-us-after-shape.svg") no-repeat;
  background-size: cover;
  background-position: right;
}

/****our services sec css **/


.home-main .ourservices {
  padding-bottom: 130px;
  scroll-margin-top: 120px;
}

.home-main .ourservices .row {
  row-gap: 25px;
}

.home-main .our-services-box {
  box-shadow: 0px 0px 15px 0px #00000026;
  text-align: center;
  height: 100%;
}

.home-main .services-img {
  max-height: 169px;
  overflow: hidden;
}

.home-main .services-img img {
  width: 100% !important;
  height: 100% !important;
}

.home-main .services-img {
  max-height: 169px;
  overflow: hidden;
  border-bottom: 7px solid var(--orange-color);
}

.home-main .services-icon-contain {
  background: #295199;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 auto;
  padding: 10px !important;
  margin-top: -60px;
}

.home-main .services-icon-contain img {
  max-width: 100%;
  object-fit: contain;
}

.home-main .services-box-data {
  position: relative;
  padding: 0px 35px 35px 35px;
}

.home-main .ourservices h2 {
  margin-bottom: 60px;
}


/***portfolio css***/

.home-main section.our-portfolio {
  padding-bottom: 130px;
  scroll-margin-top: 120px;
}

.home-main .gallery-wrap,
.home-main #gallery {
  overflow: hidden;
}

.home-main #filters {
  list-style: none;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0px 40px !important;
  gap: 13px;
}

.home-main #filters li span {
  display: block;
  padding: 16px 33px;
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
  transition: all ease-in-out 0.25s;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  background: #0000001A;
  transition: all 0.5s ease;
  text-align: center;
}

.home-main #filters li:hover span {
  background: var(--orange-color);
  color: var(--white-color);
  transition: all 0.5s ease;
}

.home-main #filters li span.active {
  background: var(--orange-color);
  color: var(--white-color);
  box-shadow: 0px 0px 9px 0px #00000040;
  transition: all 0.5s ease;
}


.home-main .gallery-item {
  width: 33.333%;
  padding: 0px 20px 20px;
  position: relative;
  z-index: 10;
  display: none;
}

.home-main .inside {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.home-main .details,
.home-main .overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
}

.home-main .details {
  background: #f7f7f7;
  position: absolute;
  top: unset !important;
  bottom: 0 !important;
  transition: 0.5s;
  padding: 10px 0;
  left: 0;
  width: 100%;
  transform: translate(0%, 50%) !important;
  height: max-content;
  z-index: 2;
  opacity: 0;

}

.home-main .inside img {
  width: 100%;
  height: 218px;
  object-fit: cover;
}

.home-main .overlay {
  background: #295199E5;
  z-index: 1;
  transition: all 0s ease 0s;
}

/* .gallery-item .details,
.gallery-item.overlay {
  transform: scale(0.6);
  transition: all 0.7s ease-in-out;
} */

.home-main .gallery-item:hover .details,
.home-main .gallery-item:hover .overlay {
  transform: translate(0%, 0%) !important;
  transition: all 0.6s ease 0s;
  opacity: 1 !important;
}

.home-main .inside img {
  transition: all 0.6s ease 0s;
}

.home-main .gallery-item:hover .inside img {
  transform: translateY(-50px);
  transition: all 0.6s ease 0s;
}

.home-main .details .text h3 {
  margin: 0px !important;
  color: #000 !important;
  padding: 0px 12px !important;
  text-align: center;
  text-transform: capitalize;
}

.home-main .gallery-item {
  float: left;
}

.home-main div#gallery h4 {
  font-size: 20px;
  font-weight: 600;
  color: #000000 !important;
  margin-top: 20px;
  margin-bottom: 0 !important;
  text-transform: uppercase;
  transition: all 0.5s ease-in-out;
}

.home-main a.gallery-item:hover h4 {
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.home-main div#gallery a {
  text-decoration: none !important;
}

.home-main .details h3 {
  color: #fff !important;
  text-transform: uppercase;
}

.home-main a.load-more {
  width: max-content;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  background: #295199;
  text-decoration: none;
  color: #fff;
  padding: 18px 57px;
  margin: 0 auto;
  margin-top: 50px;
  cursor: pointer !important;
}

@media (max-width: 30em) {
  .home-main .wrap {
    padding-left: 1em;
    padding-right: 1em;
  }

  .home-main .gallery-item {
    width: 100%;
  }
}

/***contactus-sectioncontactus-section css start here***/
.home-main section#contactus {
  scroll-margin-top: 94px;
}

.home-main .contactus-section {
  background-color: var(--blue-color);
  padding-top: 110px;
  height: 630px;
}

.home-main .contactus-section h2 {
  text-align: center;
  color: var(--white-color) !important;
  margin-bottom: 40px;
}

.home-main .contactus-section h2 span {
  color: var(--orange-color);
}

.home-main .contactus-section p {
  color: var(--white-color);
  text-align: center;
}

.home-main footer {
  background-color: var(--dark-color);
  background: url('../Images/contact-form-bg.webp') no-repeat;
  background-size: cover;
  min-height: 800px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.home-main .contact-form {
  background: #fff;
  text-align: center;
  padding: 90px 0px;
  margin-top: 90px;
  position: relative;
}


.home-main .contact-form:before {
  content: '';
  position: absolute;
  left: -231px;
  top: -115px;
  width: 376px;
  height: 284px;
  background: url('../Images/form-before-shape.svg') no-repeat;
}

.home-main .contact-form:after {
  content: '';
  position: absolute;
  right: -229px;
  top: -115px;
  width: 376px;
  height: 284px;
  background: url('../Images/form-after-shape.svg') no-repeat;
}


.home-main .contact-form input,
.home-main .contact-form textarea,
.home-main .contact-form select {
  border: none;
  border-bottom: 1px solid #808080;
  width: 100%;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: #808080;
  height: 35px;
  margin-bottom: 25px;
}

.home-main .contact-form form {
  padding: 0 142px;
  position: relative;
  z-index: 1;
}

.home-main .contact-form input:focus,
.home-main .contact-form textarea:focus,
.home-main .contact-form select:focus {
  outline: none !important;
}

.home-main .contact-form input[type="submit"] {
  border: 1px solid #295199;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #295199;
  background: transparent;
  width: max-content;
  padding: 8px 50px !important;
  min-height: 49px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  line-height: 3px;
  margin-top: 40px;
  margin-bottom: 0px !important;
}


/***footer csss***/


.home-main .footer-bg {
  width: 100% !important;
  background: #221F1FF2;
  padding-bottom: 30px;
  padding-top: 100px;
}

.home-main .row.copyright {
  margin-top: 57px;
  border-top: 1px solid #fff;
  padding-top: 15px;
}

.home-main .footer-logo-brand {
  padding-right: 40px;
}

.home-main footer.footer-sec .row .col-md-4 {
  border-right: 1px solid #fff;
}

.home-main footer.footer-sec .row .col-md-4:last-child {
  border-right: 0px solid #fff;
  text-align: center;
}

.home-main .footer-column-sec img {
  max-width: 200px;
}

.home-main .footer-logo-brand p {
  margin-top: 12px;
}

.home-main footer p {
  color: var(--white-color);
}

.home-main .footer-column-sec h3 {
  font-family: Mukta;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 33.24px;
  color: #FE5F13 !important;
  text-align: center;
}

.home-main footer a {
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-decoration: none;
  color: var(--white-color);
}

.home-main .footer-column-sec ul li {
  text-align: center;
  margin-bottom: 0;
}

.home-main .social-links ul {
  display: flex;
  gap: 25px;
  justify-content: flex-end;
}

.home-main .social-links ul li {
  margin: 0px;
}

.home-main .social-links ul li a {
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
  color: var(--white-color);
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.wrapper h1 {
  font-size: 3rem;
  margin-top: 20px;
}
.wrapper .message {
  font-size: 1.5rem;
  padding: 20px;
  width: 60%;
  text-align: center;
}
.wrapper .btn {
  background: rgb(0,195,154);
  padding: 20px;
  font-size: 1.5rem;
  text-decoration: none;
  color: #fff;
}
.wrapper .btn:hover {background: rgb(0,231,201);}
.wrapper .copyRights {margin-top: 50px;}

section.errro-page img {
  max-width: 500px;
  margin: 0 auto;
}
.wrapper h1 {
  font-family: Mukta !important;
  margin: 36px 0px 0px;
  color:#295199;
  text-align: center;
}
section.errro-page {
  height: 100vh;
  display: flex;
  align-items: center;
  text-align: center;
}
.wrapper a.back-home-btn {
  border: 1px solid var(--orange-color);
  color: var(--orange-color);
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 170px;
  text-decoration: none !important;
  margin-top: 30px;
}
