


@media screen and (min-device-width: 1200px) and (max-device-width: 1480px) { 
    .container {
        max-width: 83%;
    }
}


@media all and (min-width:1480px) {
    .marketing-main section.banner-sec .container a.scroll-bottom:before {
        bottom: -105px;
        height: 105px;
    }
    .marketing-main .websitehelp h3 {
        font-size: 20px !important;
        line-height: 30px !important;
      }

    .marketing-main .row.lastchallenges-row .info-box-challenges {
        min-height: 338px !important;
    }

    .marketing-main .common-faced-challenges {
        background-position: center 116px;

    }

    .marketing-main .common-faced-challenges .info-box-challenges {
        min-height: 337px;
    }

    .marketing-main .common-faced-challenges .col-md-4>div:last-child {
        min-height: 320px !important;
    }

    .marketing-main .faq-data .accordion-header span {
        min-width: 40px;
    }

}

@media all and (max-width:1480px) {
    .marketing-main header {
        padding-top: 30px;
    }

    .marketing-main p {
        font-size: 14px;
        line-height: 24px;
    }

    .marketing-main a {
        padding: 14px 51px;
    }

    .marketing-main h2 {
        font-size: 40px !important;
        line-height: 55px !important;
    }

    .marketing-main .brokerage-text h3,
    .marketing-main .digital-marketing-box h3 {
        font-size: 24px !important;
        line-height: 30px !important;
        margin: 0px 0px 15px !important;
    }

    .marketing-main h3 {
        font-size: 20px !important;
        line-height: 26px !important;
    }

    .marketing-main .main-btn-site {
        padding: 16px 38px;
    }

    .marketing-main header a.brand-logo img {
        max-width: 199px;
    }
    .marketing-main .banner-sec {
        padding: 86px 0px;
        height: 100vh;
        overflow: hidden !important;
    }

    .marketing-main .banner-img {
        margin-top: 35px;
    }
    .marketing-main .banner-content h1 {
        font-size: 60px;
        line-height: 72px;
    }

    .marketing-main .banner-content h1 span:before {
        height: 60px;

    }

    .marketing-main .banner-img img {
        max-width: 85%;
        float: right;
    }

    .marketing-main .common-faced-challenges {
        background-size: contain;
    }

    .marketing-main .common_challenges {
        padding: 80px 0px;
        text-align: center;
    }
    .marketing-main .websitehelp h3 {
        font-size: 16px !important;
        line-height: 24px !important;
        margin-top: 15px !important;
      }
    .marketing-main .websitehelp{
        padding: 80px 0px;
    }

    .marketing-main .elevate-brokerage {
        padding: 80px 0px;
    }

    .marketing-main .info-box-challenges {
        padding: 30px 0px 20px;
    }

    .marketing-main .info-box-challenges p {
        padding: 20px 20px 0px;
    }

    .marketing-main .common-faced-challenges .col-md-4>div:last-child {
        margin-top: 40px;
    }

    
    .marketing-main .icon-contain {
        min-width: 60px;
        min-height: 60px;
    }

    .marketing-main .icon-contain svg {
        width: 100%;
        padding: 5px;
    }

    .marketing-main .info-box-challenges.right-side-info {
        right: 46px;
    }

    .marketing-main .info-box-challenges.left-side-info {
        left: 46px;
    }

    .marketing-main .row.lastchallenges-row .info-box-challenges {
        min-height: 274px;
    }

    .marketing-main .digital-marketing {
        padding-bottom: 80px;
    }

    .marketing-main .digital-marketing-box h3 {
        margin-bottom: 20px !important;
    }

    .marketing-main .digital-marketing-box {
        padding: 67px 58px;
    }

    .marketing-main .about-us-data {
        padding: 90px 40px;
    }

    .marketing-main .faq-data .accordion-button {
        font-size: 18px;
        line-height: 26px;
        padding-left: 20px;
    }

    .marketing-main .faq-data .accordion-body {
        padding: 20px 30px 20px 60px;
    }

    .marketing-main .faq-data {
        padding: 80px 0px;
    }

    .marketing-main section.faq-data h2 {
        margin-bottom: 36px;
    }

    .marketing-main .faq-img img {
        max-width: 80%;
    }

    .marketing-main .contact-us, .seo-main .contact-us {
        padding: 80px 0px 0px;
    }

    .marketing-main .contact-us .highlight-texts, .seo-main .contact-us .highlight-texts {
        margin: 40px 0px !important;
    }

    .marketing-main .contact-us .highlight-texts li, .seo-main .contact-us .highlight-texts li {
        font-size: 18px;
    }

  
.marketing-main .contact-from input::placeholder,
.seo-main .contact-from input::placeholder,
.marketing-main .contact-from textarea::placeholder,
.seo-main .contact-from textarea::placeholder {
  font-size: 14px !important;
}
.marketing-main .contact-from input,
.seo-main .contact-from input,
.marketing-main .contact-from textarea,
.seo-main .contact-from textarea,
.marketing-main .contact-from select,
.seo-main .contact-from select,
.marketing-main .contact-from select option,
.seo-main .contact-from select option  
{
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21.13px;
  height: 50px;

}
.marketing-main .contact-from textarea,
.seo-main .contact-from textarea {
    height: 130px !important;
    padding-top: 20px !important;
  }
  .marketing-main .contact-form select, .seo-main .contact-form select{
    background-size:15px !important;
  }
  .marketing-main .contact-from input[type="Submit"], .seo-main .contact-from input[type="Submit"] {
    padding: 15px 10px !important;
}
}

@media all and (max-width:1380px){
    .marketing-main .banner-img {
        margin-top: 60px;
    }
    .marketing-main .banner-img img {
        max-width: 76%;
    }
    .marketing-main section.banner-sec .container a.scroll-bottom {
        height: 75px !important;
        position: absolute !important;
        bottom: 0px !important;
    }
    .marketing-main section.banner-sec .container a.scroll-bottom:before {
        content: '';
        position: absolute;
        left: 13px;
        bottom: -63px;
        width: 1px;
        height: 87px;
        background: white;
    }
}

@media all and (max-width:1300px){
    .marketing-main .banner-content h1 {
        font-size: 45px;
        line-height: 57px;
    }
    .marketing-main .banner-content h1 span:before {
        height: 45px;
    }
    .marketing-main section.common_challenges h2 {
        margin-bottom: 40px;
        text-align: center !important;
    }
    .marketing-main h2 {
        font-size: 34px !important;
        line-height: 45px !important;
    }
    .marketing-main h3 {
        font-size: 17px !important;
        line-height: 23px !important;
    }
    .marketing-main section.elevate-brokerage h2 {
        margin-bottom: 40px;
    }
   .marketing-main  .digital-marketing-box {
        padding: 50px 40px;
    }
    .marketing-main a {
        font-size: 14px !important;
    }
    .marketing-main .faq-data .accordion-button, .seo-main .faq-data .accordion-button {
        font-size: 16px;
        line-height: 22px;
    }
}

@media all and (max-width:1199px){
    .marketing-main .container {
        max-width: 90%;
    }
}

@media all and (max-width:992px){
    .marketing-main .container {
        max-width: 90%;
    }
    .marketing-main .banner-content h1 {
        font-size: 37px;
        line-height: 47px;
    }
    .marketing-main .banner-content h1 span:before {
        height: 40px;
    }
    .marketing-main .top-bar-info {
        padding: 12px 12px;
        gap: 13px;
    }
    .marketing-main .icon-contain {
        min-width: 45px;
        min-height: 45px;
    }
    .marketing-main .icon-contain svg {
        padding: 8px;
    }
    .marketing-main .info-box-challenges {
        padding: 20px 0px 20px;
    }
    .marketing-main .websitehelp .data-content {
        width: 100%;
    }
    .marketing-main .contact-us .highlight-texts li, .seo-main .contact-us .highlight-texts li {
        font-size: 15px;
    }
    .marketing-main .contact-infomation, .seo-main .contact-infomation {
        padding: 30px 25px;
    }
    .marketing-main p.last-p-contact br, .seo-main p.last-p-contact br {
        display: none;
    }
    .marketing-main .contact-form select , .seo-main .contact-form select {
        text-overflow: ellipsis;
    }
}


@media all and (max-width:767px){
    img.main-img-top {
        height: 100% !important;
        object-fit: cover;
    }

    .marketing-main header a.brand-logo img {
        max-width: 209px;
        width: 100%;
    }

   .marketing-main  section.banner-sec .container a.scroll-bottom {
        display: none !important;
    }
    section.banner-sec {
        height: 100% !important;
        padding: 50px 0px 86px !important;
    }
    .marketing-main .contact-us:before, .seo-main .contact-us:before {
        opacity: 0.3;
    }
    .marketing-main section.banner-sec .col-md-7 {
        justify-content: center;
    }
    .marketing-main section.banner-sec .row {
        flex-direction: column-reverse;
    }
    
    .marketing-main .lastchallenges-row {
        margin-top: 0px !important;
        gap: 0;
        flex-wrap: wrap;
    }
    .marketing-main .col-md-4.offset-md-2.ms-0 {
        margin-right:0%;
      }

    .marketing-main .banner-img {
        float: none;
        display: flex;
        justify-content: center;
    }
    .marketing-main .banner-img img {
        max-width: 255px;
    }
    .marketing-main .banner-content h1 {
        font-size: 30px;
        text-align: center;
        margin-top: 36px;
        line-height: 42px !important;
    }
    .marketing-main .banner-content h1 span:before {
        height: 34px;
    }
    .marketing-main .elevate-brokerage {
        background-image: 
            none, 
            url("../marketing-images/website-development-bg.svg"), url("../marketing-images/dots-effct-web.svg");
    }
    .marketing-main .common-faced-challenges .row{
        row-gap: 10px;
    }
    .marketing-main .row.lastchallenges-row {
        row-gap: 19px;
        margin-top: 20px !important;
    }
    .marketing-main .row.lastchallenges-row .info-box-challenges {
        min-height: 100%;
    }
    .marketing-main .common-faced-challenges{
        background: transparent !important;
    }
   .marketing-main  .common-faced-challenges .row .col-md-4 {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        row-gap: 20px;
    }
   .marketing-main  .container {
        max-width: 100%;
        padding: 0px 20px;
    }

    .marketing-main .common-faced-challenges .col-md-4>div:last-child,.common-faced-challenges .col-md-4>div:first-child{
        margin: 0px !important;
    }
   .marketing-main  .info-box-challenges.right-side-info, .info-box-challenges.left-side-info{
        position: unset !important;
    }
   .marketing-main  section.common_challenges h2 {
        margin-bottom: 26px;
        text-align: center !important;
    }
   .marketing-main  h2 {
        font-size: 26px !important;
        line-height: 33px !important;
    }
    .marketing-main .websitehelp h2 {
        padding-bottom: 10px;
        margin-bottom: 0px;
    }
   .marketing-main  section.elevate-brokerage h2 {
        margin-bottom: 20px;
    }
    .marketing-main .brokerage-text {
        padding-left: 0;
        margin-top: 20px;
    }
   .marketing-main  .elevate-brokerage {
        padding: 60px 0px;
    }
   .marketing-main  .digital-marketing-box {
        padding: 30px 30px;
    }
    .marketing-main .digital-marketing {
        padding-bottom: 60px;
    }
    .marketing-main .about-us-data {
        padding: 60px 30px;
    }
   .marketing-main  .faq-data {
        padding: 60px 0px;
    }
    .marketing-main section.faq-data h2 {
        margin-bottom: 22px;
        text-align: center;
    }
    .marketing-main .faq-img img {
        display: flex;
        margin: 0 auto;
    }
    .marketing-main .faq-img {
        margin-bottom: 40px;
    }
   .marketing-main  .common_challenges{
        padding: 60px 0px;
    }
    .marketing-main .websitehelp {
        padding: 60px 0px;
    }
    .marketing-main .contact-us, .seo-main .contact-us {
        padding: 60px 0px 0px;
    }
   .marketing-main  .contact-us .highlight-texts, .seo-main  .contact-us .highlight-texts  {
        flex-wrap: wrap;
        row-gap: 0px;
        margin: 23px 0px !important;
    }
   .marketing-main  .contact-us .highlight-texts li, .seo-main  .contact-us .highlight-texts li {
        font-size: 14px;
    }
    .marketing-main .contact-us .highlight-texts li svg, .seo-main .contact-us .highlight-texts li svg {
        width: 18px;
    }
    .marketing-main .contact-infomation, .seo-main .contact-infomation {
        margin-top: 30px;
    }
    .marketing-main .contact-from, .seo-main .contact-from {
        padding: 35px 35px;
    }
   .marketing-main  .contact-form-sec, .seo-main  .contact-form-sec {
        padding-bottom: 70px;
    }
    .marketing-main .footer-copyright p, .seo-main .footer-copyright p {
        text-align: center !important;
        padding-bottom: 20px;
    }
    .marketing-main .contact-form:before, .seo-main .contact-form:before{
        display: none;
    }
}

@media all and (max-width:575px){
    .marketing-main .container {
        max-width: 100%;
        padding: 0px 20px;
    }
   .marketing-main  header {
        padding-top: 20px;
    }
   .marketing-main  .banner-img img {
        max-width: 280px;
    }

   .marketing-main  .websitehelp h3 {
        font-size: 16px !important;
        line-height: 19px !important;
        margin-top: 15px !important;
    }
    .marketing-main .websitehelp .inner_col {
        padding: 3px 14px 6px;
    }
    .marketing-main .digital-marketing-box img {
        margin: 0px 0px 30px;
    }
    .marketing-main .digital-marketing-box {
        padding: 20px;
    }
   .marketing-main  h3, .seo-main .contact-from h3 {
        font-size: 15px !important;
        line-height: 20px !important;
    }
   .marketing-main  a.contactus-btn {
        padding: 10px 27px;
    }
    .marketing-main .contact-from, .seo-main .contact-from  {
        padding: 20px;
    }
   .marketing-main  .contact-from input[type="Submit"], .seo-main  .contact-from input[type="Submit"] {
        margin-top: 15px;
        font-size: 14px !important;
    }
    .marketing-main section.faq-data .accordion-button::after,
    .marketing-main section.faq-data .accordion-button:not(.collapsed)::after {
        width: 50px !important;
        height: 50px !important;
    }
    .marketing-main .faq-data span.content-btn-according, .seo-main .faq-data span.content-btn-according {
        max-width: 70% !important;
    }
    .marketing-main .faq-data .accordion-button, .seo-main .faq-data .accordion-button {
        font-size: 14px;
        line-height: 20px;
        padding-left: 10px;
    }
}