@media all and (max-width:1800px) {
    .home-main .about-company {
        max-width: 787px;
    }
}

@media all and (max-width:1530px) {
    .home-main .about-company {
        max-width: 762px;
    }
}

@media all and (min-width:1480px) {
    .home-main .main-slider-one__shape.main-slider-one__shape--one img {
        position: relative;
        bottom: 8px;
    }

    .home-main .user-description ul li {
        margin-bottom: 8px;
    }
}

@media all and (max-width:1480px) {
    .home-main h3 {
        font-size: 18px !important;
        line-height: 30px;
        margin: 13px 0px 8px !important;
    }

    .home-main h4 {
        font-family: Mukta;
        font-size: 18px !important;
        margin-bottom: 5px !important;
    }

    .home-main p {
        font-size: 13px;
        line-height: 20px !important;
    }

    .home-main header nav.navbar.navbar-expand-lg {
        padding: 22px 0px !important;
    }

    .home-main .main-slider-one__item {
        padding-top: 180px;
        padding-bottom: 221px;
    }

    .home-main .main-slider-one__content {
        max-width: 890px;
        height: 350px;
    }

    .home-main .main-slider-one__title {
        font-size: 37px !important;
        line-height: 42px !important;
        margin: 28px 0px 35px;
    }

    .home-main .main-slider-one__sub-title span {
        padding: 5px 33px;
        font-size: 13px;
        font-weight: 400;
        line-height: 30px;
    }

    .home-main .main-slider-one__shape.main-slider-one__shape--one img {
        height: 522px !important;
        position: relative;
        bottom: 15px;
    }

    .home-main .main-slider-one__link a {
        font-size: 13px;
        line-height: 22px;
        padding: 10px 59px;
    }

    /**about sec**/
    .home-main .about-company {
        margin-left: 143px;
    }

    .home-main .about-company {
        margin-left: auto;
        max-width: 85%;
    }

    .home-main .aboutus-sec h2 {
        font-size: 36px !important;
        line-height: 42px !important;
        margin-bottom: 20px;
    }

    .home-main p.sub-heading {
        font-size: 14px;
        line-height: 25px;
    }

    .home-main .why-select-us {
        margin-top: 35px;
    }

    .home-main .our-features-details {
        margin-top: 35px;
    }

    .home-main .about-company a.contact-us-btn {
        margin-top: 35px;
    }

    .home-main .why-select-us-box {
        gap: 15px;
    }

    .home-main .icon-img-select img {
        max-width: 70px;
    }

    .home-main .icon-img-select {
        max-width: 70px !important;
        width: 100%;
    }

    .home-main .our-features-details h4 {
        font-size: 17px !important;
        line-height: 25px;
    }

    .home-main .ourservices {
        padding-bottom: 80px;
    }

    .home-main .aboutus-sec h2,
    .home-main .ourservices h2,
    .home-main h2 {
        font-size: 36px !important;
        line-height: 42px !important;
    }

    .home-main .aboutus-sec h2,
    .home-main .ourservices h2 {
        margin-bottom: 25px;
    }

    .home-main .services-box-data {
        padding: 0px 20px 20px 20px;
    }

    .home-main section.our-portfolio {
        padding-bottom: 80px;
    }

    .home-main #filters {
        margin: 25px 0px 40px !important;
        gap: 13px;
    }

    .home-main a.load-more {
        font-size: 14px;
        margin-top: 35px;
        padding: 13px 40px;
    }

    .home-main div#ourservices h2 {
        margin-bottom: 40px;
    }

    .home-main ul li {
        font-size: 13px;
        line-height: 23px;
    }

    .home-main #filters li span {
        padding: 11px 28px;
        font-size: 13px;
    }

    .home-main .user-description ul li {
        margin-bottom: 6px;
    }

    .home-main .contact-form form input,
    .home-main .contact-form form textarea,
    .home-main .contact-form form select {
        font-size: 13px;
    }

    .home-main .contact-form input[type="submit"] {
        font-size: 13px;
        padding: 7px 45px !important;
        min-height: 43px;
    }

    .home-main .contact-form:before {
        left: -68px;
        top: -45px;
        width: 282px;
        height: 240px;
    }

    .home-main .contact-form:after {
        right: -90px;
        top: -45px;
        width: 282px;
        height: 242px;
    }

    .home-main .contactus-section h2 {
        margin-bottom: 22px;
    }

    .home-main .contactus-section {
        padding-top: 40px;
        height: 500px;
    }

    .home-main .contact-form {
        margin-top: 40px;
        padding: 40px 0px;
    }

    .home-main .contact-form form {
        padding: 0 60px;
    }

    .home-main .contact-form input[type="submit"] {
        margin-top: 25px;
    }

    .home-main footer {
        min-height: 730px;
    }

    .home-main footer a,
    .home-main .social-links ul li a {
        font-size: 13px;
        line-height: 24px;
    }
}

@media all and (max-width:1340px) {
    .home-main .about-company {
        margin-left: auto;
        max-width: 92.5%;
    }

    .home-main .icon-img-select {
        max-width: 52px !important;
    }

    .home-main .greate-platform {
        gap: 11px;
    }

    .home-main .icon-img-select img {
        max-width: 55px;
    }

    .home-main .icon-img-select {
        max-width: 52px !important;
    }

    .home-main .aboutus-sec {
        padding: 70px 0px 70px;
    }

    .home-main .ourservices {
        padding-bottom: 70px;
    }

    .home-main section.our-portfolio {
        padding-bottom: 70px;
    }
}
@media all and (max-width:1199px) {
    .home-main .container {
        max-width: 93%;
    }

    .home-main .our-features-details h4 {
        font-size: 14px !important;
        line-height: 22px;
    }
}

@media all and (max-width:992px) {

    .home-main body.bg-show {
        position: relative;
    }
    .home-main body.bg-show:before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.7;
        z-index: 1000;
    } 

    .home-main header {
        height: 75px;
        display: flex;
        align-items: center;
        z-index: 998;
    }
    .home-main .offcanvas-header {
        display: block;
    }

    .home-main .mobile-offcanvas {
        visibility: hidden;
        transform: translateX(-100%);
        border-radius: 0;
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 1200;
        width: 80%;
        overflow-y: scroll;
        overflow-x: hidden;
        transition: visibility .3s ease-in-out, transform .3s ease-in-out;
    }

    .home-main .mobile-offcanvas.show {
        visibility: visible;
        transform: translateX(0);
        background: #295199;
        z-index: 999999;
    }
    .home-main .mobile-offcanvas .container,
    .home-main .mobile-offcanvas .container-fluid {
        display: block;
    }
    .home-main .mobile-menu-btn {
        float: right;
        background: transparent !important;
        border: none;
        padding: 0px !important;
    }

    .home-main .navbar-toggler:focus {
        box-shadow: none !important;
    }

    .home-main .mobile-offcanvas ul.navbar-nav {
        margin-top: 8px !important;
    }

    .home-main .mobile-offcanvas li.nav-item {
        border-bottom: 1px solid #ccc;
        margin-bottom: 12px;
        padding: 3px 0px 10px!important;
    }

    .home-main .mobile-offcanvas li.nav-item:last-child {
        padding-bottom: 0px !important;
        margin-bottom: 0px !important;
        border: none;
        padding-top: 8px !important;
        line-height: 0 !important;
    }
    .home-main nav.mobile-offcanvas.navbar.navbar-expand-lg.show .offcanvas-header {
        display: block !important;
        height: 62px;
        border-bottom: 1px solid #ccc;
    }
    
    .home-main nav.mobile-offcanvas.navbar.navbar-expand-lg.show .collapse:not(.show) {
        display: block;
    }
    .home-main .mobile-offcanvas button.btn-close.float-end {
        background: #fff;
        width: 37px;
        height: 37px;
        border-radius: 50%;
        right: 16px;
        position: relative;
        opacity: 1;
    }
    .home-main .mobile-offcanvas button.btn-close.float-end img {
        max-width: 100%;
        padding: 11px;
    }

    .home-main .mobile-offcanvas .nav-link {
        padding: 0px 25px !important;
        font-family: Mukta;
        font-size: 16px;
        font-weight: 400;
        color: #fff;
    }
    /* .home-main .mobile-offcanvas ul li:last-child .nav-link {
        border: 1px solid #FE5F13;
        color: #FE5F13;
        padding: 19px 30px!important;
        width: max-content;
        margin-left: 25px;
    } */
    .home-main .navbar-nav .nav-link.active, .home-main .navbar-nav .nav-link.show {
        color: #fff;
    }

    .home-main .greate-platform {
        flex-direction: column;
        padding: 10px !important;
        text-align: center;
    }

    .home-main .services-icon-contain {
        width: 82px;
        height: 82px;
        margin-top: -50px;
    }

    .home-main .services-icon-contain img {
        max-width: 48px !important;
    }
}


@media all and (max-width:918px) {

    .home-main #filters li span {
        padding: 7px 17px;
        font-size: 12px;
    }
    section.errro-page img {
        max-width: 100%;
        margin: 0 auto;
    }
}


@media all and (max-width:767px) {
    .home-main .container {
        max-width: 100%;
        padding: 0px 20px;
    }

    .home-main header a.navbar-brand img {
        width: 150px;
    }

    .home-main .main-slider-one__item {
        padding-top: 170px;
        padding-bottom: 107px;
    }

    .home-main .about-company {
        padding: 0px 20px;
        max-width: 100%;
    }

    .home-main .greate-platform {
        flex-direction: row;
        padding: 32px 14px !important;
        text-align: left;
    }

    .home-main .user-img img {
        width: 95px !important;
    }

    .home-main .it-soluctions-img {
        margin-top: 30px;
    }
    .home-main .about-company {
        padding-top: 0px !important;
    }
    .home-main .it-soluctions-img img {
        width: 100%;
        max-height: 510px;
    }

    .home-main .about-company {
        padding-top: 30px;
    }

    .home-main .gallery-item {
        width: 50%;
    }

    .home-main .contactus-section p br {
        display: none;
    }

    .home-main .contact-form form {
        padding: 0 50px;
    }

    .home-main .contact-form {
        padding: 50px 0px;
    }

    .home-main footer {
        min-height: 1025px;
        text-align: center;
    }

    .home-main .footer-bg {
        padding-top: 70px;
    }

    .home-main .social-links ul {
        justify-content: center;
        margin-top: 6px !important;
    }
    .home-main .details {
        position: unset;
        transform: unset !important;
        opacity: 1 !important;
        background: #f1f1f1d1;
    }
    .home-main .inside {
        display: flex;
        flex-direction: column-reverse;
    }
    .home-main .gallery-item:hover .inside img{
        transform: unset !important;
    }
    section.errro-page img {
        max-width: 70%;
        margin: 0 auto;
    }
    .wrapper h1 {
        margin: 25px 0px 0px;
        font-size: 33px;
        line-height: 40px;
    }
    .wrapper a.back-home-btn {
        margin-top: 25px;
    }
}

@media all and (max-width:575px) {
    .home-main .main-slider-one__content {
        height: 324px;
    }
    .home-main .main-slider-one .active .main-slider-one__title br{
        display: none;
    }
    .home-main .main-slider-one__title {
        font-size: 28px !important;
        line-height: 32px !important;
        margin: 2px 0px 25px;
    }
    .home-main .our-features-details h4 {
        font-size: 19px !important;
        line-height: 27px;
    }
    .home-main .user-img {
        width: max-content;
        overflow: hidden;
        min-width: 47px !important;
    }
    .home-main .greate-platform {
        gap: 22px;
    }

    .home-main .why-select-us {
        margin-top: 20px;
    }

    .home-main .why-select-us .row {
        gap: 20px;
    }

    .home-main .our-features-details {
        margin-top: 23px;
    }

    .home-main .aboutus-sec h2,
    .home-main .ourservices h2,
    .home-main h2 {
        font-size: 26px !important;
        margin-bottom: 10px !important;
        line-height: 30px !important;
        margin-top: 10px;
    }

    .home-main .user-description {
        padding-left: 0;
        margin-top: 20px;
    }

    .home-main div#ourservices .row {
        margin-top: 30px;
    }

    .home-main #filters {
        margin: 15px 0px 34px !important;
    }

    .home-main ul#filters {
        flex-wrap: nowrap;
        justify-content: flex-start;
        overflow: scroll;
    }

    .home-main ul#filters li span {
        width: max-content !important;
    }

    .home-main .gallery-item {
        width: 100%;
        padding: 0px 0px 15px;
    }

    .home-main .contactus-section {
        padding-top: 70px;
        height: 530px;
    }

    .home-main .contact-form form {
        padding: 0 30px 10px;
    }

    .home-main .contact-form input[type="submit"] {
        margin-top: 8px;
    }

    .home-main .contact-form {
        padding: 30px 0px;
    }

    .home-main footer.footer-sec .row .col-md-4 {
        border-right: none;
        padding: 0;
    }

    .home-main .footer-bg {
        padding-bottom: 10px;
    }
}
